<template>
  <div class="container">
    <div class="base-tumbler" :class="classes" @click="changeState">
      <div class="base-tumbler__circle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tumbler',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasAnimation: true,
    };
  },
  computed: {
    classes() {
      const base = 'base-tumbler';
      return {
        [`${base}--has-animation`]: this.hasAnimation,
        [`${base}--disabled`]: this.disabled,
        [`${base}--left`]: this.value,
      };
    },
  },
  methods: {
    changeState() {
      if (!this.hasAnimation) this.hasAnimation = true;
      if (!this.disabled) this.$emit('change', !this.value);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@keyframes swipeRight {
  from {
    left: 3px;
  }
  to {
    left: 32px;
  }
}
@keyframes swipeLeft {
  from {
    left: 32px;
  }
  to {
    left: 3px;
  }
}

.container {
  display: flex;
  flex-direction: row;
}
.on{
  width: 3rem;
  margin-left : -1rem;
  font-bold: 500;
  color: #4cbf86
}
.off{
  width: 3rem;
  margin-right : -1rem;
  font-bold: 500;
  color: #FF0000
}
.base-tumbler {
  cursor: pointer;
  position: relative;
  padding: 3px;
  width: 56px;
  height: 26px;
  border-radius: 20px;
  background-color: var(--avatar-bg); // по чтобы видно было

  &__circle {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 4px solid var(--tumbler-border-stack);
    background-color: var(--tumbler-background-stack);
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);

    ^[0]--has-animation & {
      animation: swipeLeft 0.5s ease;
      animation-fill-mode: backwards;
      animation-fill-mode: forwards;
      border: 4px solid #FF0000;
    }

    ^[0]--disabled  {
      opacity: 0.4;
    }

    ^[0]--left & {
      left: 32px;
    }
    ^[0]--has-animation^[0]--left & {
      animation: swipeRight 0.5s ease;
      animation-fill-mode: backwards;
      animation-fill-mode: forwards;
      border: 4px solid #4cbf86;
    }
  }
}
</style>

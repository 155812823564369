<template>
  <div class="base-progress">
    <label v-if="text !== ' '" class="standart-text">{{ text }}</label>
    <ProgressBar v-if="type === 'indeterminate'" mode="indeterminate" />
    <ProgressBar v-else :value="progress" :show-value="showValue" />
  </div>
</template>

<script>
import ProgressBar from 'primevue/progressbar';
export default {
  name: 'BaseProgress',
  components: { ProgressBar },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    showValue: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'determinate',
    },
    text: {
      type: String,
      default: 'Состояние загрузки образа',
    },
    size: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    classes() {
      return this.size ? 'base-loader__sized' : 'base-loader__default';
    },
    dataLoad() {
      return this.$store.state.moduleStack.progressBar;
    },
    totalSize() {
      return this.$store.state.moduleStack.totalSize;
    },
    progress() {
      if (this.dataLoad > 0) {
        // return +((this.dataLoad / this.totalSize) * 100).toPrecision(2);
        return Math.ceil(+((this.dataLoad / this.totalSize) * 100));
        // return +((this.dataLoad / this.totalSize) * 100).toPrecision(0);
      } else return 0;
    },
  },
  // watch: {
  //   progress(event) {
  //     console.log(event);
  //     console.log(this.dataLoad);
  //     console.log(this.totalSize);
  //     console.log((this.dataLoad / this.totalSize) * 100);
  //   },
  // },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-progress {
  width: 100%
}
.base-loader__default {
  font-size: 2.5rem;
  //font-size: var(this.baseSize);
  line-height: 1;
  margin: 1em auto;
  circle(1em);
  color: var(--loader-color);
  border: 2px solid currentColor;
  border-left-color: var(--loader-border);
  animation: rotation 1s linear infinite;
}
.base-loader__sized {
  font-size: 1rem;
  //font-size: var(this.baseSize);
  line-height: 1;
  //margin: 1em auto;
  margin: auto;
  circle(1em);
  //margin-left: 1.75rem;
  color: var(--loader-color);
  border: 2px solid currentColor;
  border-left-color: var(--loader-border);
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

import NewImage from '@/layouts/Stack/components/NewImage';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import BaseProgress from '@/components/BaseLoader/BaseProgress';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      data: {},
      timerId: '',
      link: {},
      progress: 0,
      totalSize: 100,
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {
    quota() {
      return this.$store.getters['moduleStack/aviableSpaseVolume'];
    },
    projectId() {
      return this.$store.getters['moduleStack/projectId'];
    },
  },
  watch: {
    progress(event) {
      // console.log(event);
      if (event) {
        // console.log(this.modal);
        if (!this.modal) this.makeModal();
        this.$store.state.moduleStack.progressBar = event;
        Vue.set(this.modal, 'component', BaseProgress);
        // Vue.set(this.modal, 'props', { value: this.progress });
        // Vue.set(this.modal, 'size', 'default');
        Vue.set(this.modal, 'closable', false);
        // Vue.set(this.modal.component, 'props', { value: event, size: this.$store.state.moduleStack.progressBar });
        Vue.set(this.modal, 'footer', false);

        if (event === this.$store.state.moduleStack.totalSize) {
          this.showResModal('Образ загружен');
          this.$store.state.moduleStack.progressBar = 0;
          this.$store.state.moduleStack.totalSize = 0;
        }
      }
    },
  },
  methods: {
    newImage: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'newImage';
        this.$modals.open({
          name: selfName,
          size: 'big',
          component: NewImage,
          closeOnBackdrop: false,
          props: {
            quota: this.quota,
            size: 5, //требования к минимальному размеру диска
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              //прилет основных данных
              // console.log(data);
              // console.log('ready');
              instance = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            changeOs: data => {
              console.log('changeOs', data);
              if (instance) instance.os_type = data.os_type;
              if (instance) instance.os_distro = data.os_distro; //меняет ОС, если она уже проставлена
            },
            changeType: data => {
              if (instance) instance.disk_format = data.disk_format; //меняет тип, если уже был выставлен
            },
            notready: () => {
              // console.log('notready', this.modal.footer.confirm.props);
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            // centered: true,
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  // console.log(instance);
                  if (instance.from.from === 'disk') {
                    this.createImage(instance)
                      .then(async data => {
                        if (
                          ['saving', 'queued', 'importing', 'uploading'].includes(
                            data['os-volume_upload_image'].status
                          )
                        ) {
                          this.fetchStatusDisk();
                          that.$modals.close();
                        }
                      })
                      .catch(e => {
                        this.showError(e);
                      });
                  } else if (instance.from.from === 'file') {
                    this.data = instance;
                    this.$store.state.moduleStack.totalSize = 0;
                    this.$store.state.moduleStack.progressBar = 0;
                    // console.log('data', this.data, instance);
                    const newImage = {
                      name: instance.name,
                      disk_format: instance.disk_format,
                      container_format: 'bare',
                      min_disk: +instance.min_disk,
                      visibility: 'private',
                      tags: ['file'],
                      os_type: instance.os_type,
                      hw_scsi_model: instance.hw_scsi_model,
                      hw_qemu_guest_agent: instance.hw_qemu_guest_agent,
                      hw_disk_bus: instance.hw_disk_bus,
                      os_distro: instance.os_distro,
                      hw_firmware_type: instance.hw_firmware_type,
                      hw_machine_type: instance.hw_machine_type,
                    };
                    this.addNewImage(newImage)
                      .then(async data => {
                        // console.log(data);
                        const uploadFile = {
                          id: data.id,
                          file: this.data.file[0].file,
                        };
                        // console.log(uploadFile);
                        this.$store.state.moduleStack.totalSize = uploadFile.file.size;
                        this.totalSize = uploadFile.file.size;
                        // console.log(this.totalSize);
                        this.addFileToImage(uploadFile);
                        await this.showLoadingModal({});
                        this.fetchStatusDisk();
                        await this.$store.dispatch('moduleStack/resetFiles');
                        that.$modals.close();
                      })
                      .catch(e => {
                        this.showError(e);
                      });
                  } else if (instance.from.from.link) {
                    // console.log(instance);
                    this.link = {
                      method: {
                        name: 'web-download',
                        uri: instance.link,
                      },
                    };
                    const newImage = {
                      name: instance.name,
                      disk_format: instance.disk_format,
                      container_format: 'bare',
                      min_disk: instance.size,
                      visibility: 'private',
                      os_type: instance.os_type,
                      hw_scsi_model: instance.hw_scsi_model,
                      hw_qemu_guest_agent: instance.hw_qemu_guest_agent,
                      hw_disk_bus: instance.hw_disk_bus,
                      os_distro: instance.os_distro,
                      hw_firmware_type: instance.hw_firmware_type,
                      hw_machine_type: instance.hw_machine_type,
                    };
                    this.addNewImage(newImage)
                      .then(async data => {
                        // console.log(data);
                        const uploadLink = {
                          id: data.id,
                          link: this.link,
                        };
                        this.linkToImage(uploadLink);
                        that.$modals.close();
                        this.fetchStatusDisk();
                      })
                      .catch(e => {
                        this.showError(e);
                      });
                  }
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    addFileToImage(payload) {
      const params = {
        onUploadProgress: progressEvent => {
          this.progress = progressEvent.loaded;
          // console.log(progressEvent.loaded);
        },
        ...payload,
      };
      return this.$store.dispatch('moduleStack/fileToImage', params).catch(e => {
        this.showError(e);
      });
    },
    linkToImage(payload) {
      return this.$store.dispatch('moduleStack/linkToImage', payload).catch(e => {
        this.showError(e);
      });
    },
    addNewImage(payload) {
      return this.$store.dispatch('moduleStack/createImage', payload).catch(e => {
        // console.log(e);
        this.showError(e);
      });
    },
    fetchStatusDisk() {
      this.$store.dispatch('moduleStack/fetchPrivateImages').then(data => {
        this.newFetch();

        // this.showResModal('Образ загружается, дождитесь обновления статуса');
        // this.showLoadingModal('Образ загружается, дождитесь обновления статуса');
        let imagesId = data.images
          .filter(
            x =>
              x.status === 'saving' ||
              x.status === 'queued' ||
              x.status === 'importing' ||
              x.status === 'uploading'
          )
          .map(x => x.id);
        if (imagesId.length > 0) {
          imagesId.forEach(x => {
            this.checkStatus(_, x);
          });
        }
      });
    },
    createImage(params) {
      const payload = {
        id: params.id,
        params: {
          'os-volume_upload_image': {
            force: true,
            image_name: params.name,
          },
        },
      };
      return this.$store.dispatch('moduleStack/createImageFromDisk', payload);
    },
    async showLoadingModal(props = {}) {
      if (!this.modal) this.makeModal(props);
      // console.log(this.modal);
      // Vue.set(this.modal, 'text', null);
      Vue.set(this.modal, 'component', BaseProgress);
      Vue.set(this.modal, 'props', { value: this.progress, size: this.totalSize });
      // Vue.set(this.modal, 'size', 'default');
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};

<template>
  <div class="resize">
    <div class="l-col">
      <base-input
        v-model="formData.name"
        :placeholder="$t('disk.placeholder')"
        :label="$t('disk.label')"
        class="form__field--input"
        @input="onChange('name', $event)"
      >
      </base-input>
    </div>
    <div class="resize-row"></div>
    <div class="resize-row">
      <checkbox-block
        :hint="$t('disk.hint')"
        :config="config"
        :label="$t('disk.size')"
        @change="onChange('check', $event)"
      >
      </checkbox-block>
      <div v-if="config.value === 'on'" class="resize-row-text">
        <InputNumber
          v-model="formData.size"
          show-buttons
          mode="decimal"
          suffix=" ГБ"
          :min="minsize"
          :max="quota"
          button-layout="horizontal"
          :input-style="{ 'text-align': 'center', 'max-width': '4rem' }"
          decrement-button-class="p-button-blue"
          increment-button-class="p-button-blue"
          increment-button-icon="pi pi-plus"
          decrement-button-icon="pi pi-minus"
          aria-describedby="value2-help"
          @input="onChange('size', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import CheckboxBlock from '@/components/Configurator/components/CheckboxBlock';
import InputNumber from 'primevue/inputnumber';
export default {
  name: 'RenameImage',
  components: { CheckboxBlock, InputNumber, BaseInput },
  mixins: [setFocus],
  props: {
    quota: {
      type: Number,
      default: 0,
      require: true,
    },
    size: {
      type: Number,
      default: 0,
      require: true,
    },
    minsize: {
      type: Number,
      default: 0,
      require: true,
    },
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        size: this.minsize,
        name: this.instance.name,
      },
      changeData: [],
      config: {
        values: {
          on: true,
          off: false,
        },
        value: 'off',
        trueValue: 'on',
        falseValue: 'off',
      },
    };
  },
  computed: {
    minSizeDisk() {
      return this.formData.size > this.size ? this.formData.size : this.size;
    },
  },
  mounted() {
    this.$emit('notready');
    if (this.minsize) {
      this.config.value = 'on';
      this.changeData.push({
        path: '/min_disk',
        value: this.formData.size,
        op: 'replace',
      });
    }
  },
  methods: {
    onChange(item, event) {
      if (item === 'name') {
        this.formData.name = event;
        if (this.formData.name && this.formData.size) {
          if (this.changeData && this.changeData.find(x => x.path === '/name')) {
            this.changeData.find(x => x.path === '/name').value = event;
            // console.log('__1__', this.changeData);
          } else {
            this.changeData.push({ path: '/name', value: this.formData.name, op: 'replace' });
            // console.log('__2__', this.changeData);
          }
          return this.$emit('change', this.changeData);
        } else return this.$emit('notready');
      } else {
        // console.log(item, event);
        if (event && event.value === 'on') {
          this.changeData.push({
            path: '/min_disk',
            value: this.formData.size,
            op: 'replace',
          });
          // console.log('__3__', this.changeData);
        } else if (item === 'size') {
          this.formData.size = event;
          this.changeData.find(x => x.path === '/min_disk').value = event;
          // console.log('__5__', this.changeData);
        } else {
          this.changeData = this.changeData.filter(x => x.path !== '/min_disk');
          // console.log('__4__', this.changeData);
          if (this.formData.name.length > 0) this.$emit('change', this.changeData);
        }
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "disk": "Источник диска",
      "type": "Тип",
      "description": "Описание",
      "image" : "Образ",
      "disks" : "Использовать диск как ресурс",
      "snap": "Использовать снимок как источник"
    },
    "disk": {
      "label": "Имя образа:",
      "placeholder": "Имя вашего образа",
      "description" : "Текстовое описание",
      "snap" : "Использовать снимок как источник",


      "size": "Размер диска",
      "hint": "Вы можетие указать минимальный размер создаваемого диска."
    },
    "sure": {
      "confirm": "Увеличить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    margin: 1.5rem 1.5rem;
    &-text {
      margin-left: 0.5rem;
    }
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>

<template>
  <div class="resize">
    <div class="l-col">
      <label class="typo__label">{{ $t('os') }}</label>
      <base-select
        v-model="osName"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :options="typeOs"
        track-by="name"
        :custom-label="nameDiskFrom"
        size="medium"
        :value="os"
        :allow-empty="false"
        :preselect-first="true"
        class="new-disk__services"
        @input="onChangeOs"
        @change="onChangeOs"
      >
      </base-select>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
export default {
  name: 'ChangeOs',
  components: { BaseSelect },
  mixins: [setFocus],
  props: {
    os: {
      type: String,
      require: true,
      default: '',
    },
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {},
      osName: {},
    };
  },
  computed: {
    typeOs() {
      return [
        { type: 'linux', name: 'Linux' },
        { type: 'windows', name: 'Windows' },
        { type: '', name: 'Другая' },
      ];
    },
  },
  mounted() {
    this.$emit('notready');
    this.currentOs();
  },
  methods: {
    currentOs() {
      const os = {};
      this.typeOs.forEach(x => {
        if (x.type === this.os) {
          os.type = this.os;
          os.name = x.name;
        }
      });
      this.osName = Object.assign({}, os);
      return os;
    },
    nameDiskFrom({ name }) {
      return `${name}`;
    },
    onChangeOs(event) {
      this.formData.os_type = event.type.toLowerCase();
      this.formData.hw_scsi_model = 'virtio-scsi';
      this.formData.hw_qemu_guest_agent = 'yes';
      this.formData.hw_disk_bus = 'scsi';
      if (event.type === 'windows') {
        this.formData.os_distro = 'windows';
        this.formData.hw_firmware_type = 'uefi';
        this.formData.hw_machine_type = 'q35';
      } else {
        delete this.formData.os_distro;
        delete this.formData.hw_firmware_type;
        delete this.formData.hw_machine_type;
      }
      if (this.formData.os_type !== this.os && this.formData.os_type === 'windows') {
        return this.$emit('change', [
          { path: '/os_type', value: this.formData.os_type, op: 'replace' },
          { path: '/hw_qemu_guest_agent', value: this.formData.hw_qemu_guest_agent, op: 'replace' },
          { path: '/hw_disk_bus', value: this.formData.hw_disk_bus, op: 'replace' },
          { path: '/hw_scsi_model', value: this.formData.hw_scsi_model, op: 'replace' },
          { path: '/os_distro', value: this.formData.os_distro, op: 'replace' },
          { path: '/hw_firmware_type', value: this.formData.hw_firmware_type, op: 'replace' },
          { path: '/hw_machine_type', value: this.formData.hw_machine_type, op: 'replace' },
        ]);
      } else if (this.formData.os_type !== this.os && this.formData.os_type !== 'windows') {
        return this.$emit('change', [
          { path: '/os_type', value: this.formData.os_type, op: 'replace' },
          { path: '/hw_qemu_guest_agent', value: this.formData.hw_qemu_guest_agent, op: 'replace' },
          { path: '/hw_disk_bus', value: this.formData.hw_disk_bus, op: 'replace' },
          { path: '/hw_scsi_model', value: this.formData.hw_scsi_model, op: 'replace' },
        ]);
      } else return this.$emit('notready');
    },
    onChange(event) {
      Object.assign(this.formData, event);
      if (this.formData.os_type !== this.os) {
        return this.$emit('change', [{ path: '/name', value: this.formData.name, op: 'replace' }]);
      } else return this.$emit('notready');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "os": "Тип ОС",
    "label": {
      "disk": "Источник диска",
      "type": "Тип",
      "description": "Описание",
      "image" : "Образ",
      "disks" : "Использовать диск как ресурс",
      "snap": "Использовать снимок как источник"
    },
    "disk": {
      "label": "Новое имя образа",
      "placeholder": "Имя вашего диска",
      "description" : "Текстовое описание",
      "snap" : "Использовать снимок как источник",


      "size": "Минимальный размер диска"
    },
    "sure": {
      "confirm": "Увеличить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>

import ChangeOs from '@/layouts/Stack/components/ChangeOs';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      data: {},
      isProcessing: false,
    };
  },
  methods: {
    changeOs: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'ChangeOs';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: ChangeOs,
          closeOnBackdrop: false,
          props: {
            os: instance.os,
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              instance = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  this.renameImageRequest(instance)
                    .then(async () => {
                      this.newFetch();
                      that.$modals.close();
                      this.showResModal('Образ успешно изменен.');
                    })
                    .catch(e => {
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    renameImageRequest(payload) {
      payload.push({ id: this.id });
      return this.$store.dispatch('moduleStack/renameImage', payload).catch(e => {
        this.showError(e);
      });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
